import React, {useState} from "react";
import ModuleSection from '@components/modules/moduleWrapper';
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import ContactForm from '@components/forms/contactForm';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcrumbs from '@components/breadcrumbs';
import { StarberryIcons } from "@components/icons/index";
import { fadeIn } from '@components/utils/animations';

const ContactFormModule = ({ sectionClass, sectionSpacing, removeContainer, title, text, breadcrumbs, breadcrumbsClass, successHeading, successText }) => {
    // State to track form submission
    const [formSubmitted, setFormSubmitted] = useState(false);

    // Function to handle successful form submission
    const handleFormSuccess = () => {
        setFormSubmitted(true);
    };

    return(
        <ModuleSection sectionClass={sectionClass + ' target-element'} sectionSpacing={sectionSpacing} removeContainer={removeContainer} id="contactForm">
            <Row>
                <Col xs={12} variants={fadeIn}>
                {!formSubmitted ? (
                    <>
                    {breadcrumbs.length > 0 &&
                        <div>
                            <Breadcrumbs
                                breadcrumbs={breadcrumbs}
                                breadcrumbsClass={breadcrumbsClass}
                            />
                        </div>
                    }
                    {title &&
                        <ModuleTitle
                            as="h1"
                            title={title}
                            className="module__title mb-16 mb-md-24"
                        />
                    }
                    {text &&
                        <ModuleText
                            text={text}
                            className="module__text mb-32 mb-md-56"
                        />
                    }                  
                    <ContactForm onSuccess={handleFormSuccess} />
                    </> 
                    ) : (
                        // Render success message after form submission
                        <div className="success-message py-40 py-md-64 py-lg-120">
                            <StarberryIcons iconName="CheckCircleBlue" className="check-circle-blue-icon" />
                            {successHeading && <h2>{successHeading}</h2> }
                            <div className="success-text">{successText || "Thank you for your submission!"}</div>
                        </div>
                    )}
                </Col>
            </Row>
        </ModuleSection>
    )
}

export default ContactFormModule